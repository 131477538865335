<div class="card-view fx-layout-column relative">
    <!--search area-->

    <ng-template #searchTemplate>
        <div class="flex flex-col" [formGroup]="filterForm">
            <div>
                <h5 style="margin: 6px 0">{{ "MODALITY" | translate }}</h5>
                <mat-button-toggle-group
                        aria-label="modality"
                        formControlName="modality"
                        multiple
                        name="modality"
                >
                    @for (item of ['CR', 'CT', 'MG', 'MR', 'US']; track $index) {
                        <mat-button-toggle [value]="item">
                            {{ item }}
                        </mat-button-toggle>
                    }
                </mat-button-toggle-group>
            </div>

            <div>
                <h5 style="margin: 6px 0">{{ "EXAMTYPE" | translate }}</h5>
                <mat-button-toggle-group
                        aria-label="reasonForExams"
                        formControlName="reasonForExam"
                        multiple
                        name="reason"
                >
                    @for (reason of reasonForExams; track $index) {
                        <mat-button-toggle [value]="reason.value">
                            {{ reason.value }}
                        </mat-button-toggle>
                    }
                </mat-button-toggle-group>
            </div>

            <div>
                <h5 style="margin: 6px 0">{{ "PRIORITY" | translate }}</h5>
                <mat-button-toggle-group
                        aria-label="priorities"
                        formControlName="priority"
                        multiple
                        name="priority"
                >
                    @for (priority of priorities; track $index) {
                        <mat-button-toggle [matTooltip]="priority.value" [value]="priority.id">
                            <mat-icon
                                    fontIcon="mdi-circle"
                                    [ngStyle]="{
              background:
                'linear-gradient(45deg, black, ' + priority.color + ', white)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }"
                                    fontSet="mdi"
                            ></mat-icon>
                        </mat-button-toggle>
                    }
                </mat-button-toggle-group>
            </div>

            <div>
                <h5 style="margin: 6px 0">
                    {{ "APPOINTMENT_STATUS" | translate }}
                </h5>
                <mat-button-toggle-group
                        aria-label="appointment status"
                        formControlName="appointmentStatus"
                        multiple
                        name="appointmentStatus"
                >
                    @for (status of appointmentStatuses; track $index) {
                        <mat-button-toggle [matTooltip]="status | translate" [value]="status">
                            <mat-icon
                                    [fontIcon]="getOrderStatusIcon(status)"
                                    [ngStyle]="{
              background:
                'linear-gradient(45deg, black, ' +
                getOrderStatusColor(status) +
                ', white)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }"
                                    fontSet="mdi"
                            ></mat-icon>
                        </mat-button-toggle>
                    }
                </mat-button-toggle-group>
            </div>



            <div>
                <h5 style="margin: 6px 0">{{ "PERIOD" | translate }}</h5>

                <mat-button-toggle-group
                    #group="matButtonToggleGroup"
                    (change)="changeRange($event)"
                    aria-label="Period"
                    formControlName="period"
                    name="period"
                >
                    <mat-button-toggle value="1M">
                        -{{ "ONE_MONTH" | translate }}
                    </mat-button-toggle>
                    <mat-button-toggle value="1W">
                        -{{ "ONE_WEEK" | translate }}
                    </mat-button-toggle>
                    <mat-button-toggle value="TODAY">
                        {{ "TODAY" | translate }}
                    </mat-button-toggle>
                    <mat-button-toggle value="1W+">
                        +{{ "ONE_WEEK" | translate }}
                    </mat-button-toggle>
                    <mat-button-toggle value="1M+">
                        +{{ "ONE_MONTH" | translate }}
                    </mat-button-toggle>
                    <mat-button-toggle value="3M+">
                        +{{ "THREE_MONTHS" | translate }}
                    </mat-button-toggle>
                    <mat-button-toggle value="OT">
                        {{ "ALL" | translate }}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>

            @if (group.value === 'OT') {
                <div class="mt-2">
                    <mat-form-field [style.height.px]="37">
                        <mat-date-range-input [rangePicker]="picker">
                            <input
                                    [placeholder]="'FROM' | translate"
                                    formControlName="startDate"
                                    matStartDate
                                    (dateInput)="changePeriod()"
                            />
                            <input
                                    [placeholder]="'TO' | translate"
                                    formControlName="endDate"
                                    matEndDate
                                    (dateInput)="changePeriod()"
                            />
                        </mat-date-range-input>
                        <mat-datepicker-toggle
                                [for]="picker"
                                matSuffix
                        ></mat-datepicker-toggle>
                        <mat-date-range-picker
                            #picker
                            (closed)="changePeriod()"
                        ></mat-date-range-picker>

                        @if (filterForm.controls['startDate'].hasError('matStartDateInvalid'
                        )) {
                            <mat-error>Invalid start date</mat-error>
                        }
                        @if (filterForm.controls['endDate'].hasError('matEndDateInvalid')) {
                            <mat-error>Invalid end date</mat-error>
                        }
                    </mat-form-field>
                </div>
            }



        </div>
    </ng-template>


    <!--table-->
    @if (isLoadingResults) {
        <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
    }

    <div class="fx-fill-height relative fx-overflow-auto">
        <mat-table
                [dataSource]="dataSource"
                [trackBy]="trackById"
                matSort
                matSortActive="id"
                matSortDirection="desc"
                [matSortDisableClear]="true"
        >
            <!--Table-->
            @for (col of displayedColumns; track $index) {
                <ng-container [class.hidden]="col.hidden" matColumnDef="{{ col.label }}">
                    <mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header="{{ col.sortField }}"
                    >
                        {{ col.header | translate }}
                    </mat-header-cell>

                    @if (col.header === 'DATE') {
                        <mat-cell *matCellDef="let row">
                            {{ row[col.label] | date : "dd/MM/yyyy" }}
                        </mat-cell>
                    }
                    @if (col.header === 'TIME') {
                        <mat-cell *matCellDef="let row">
                            {{ row[col.label] | date : "HH:mm" }}
                        </mat-cell>
                    }
                    @if (col.header === 'Source') {
                        <mat-cell *matCellDef="let row"
                        ><span
                                [class.ft-badge]="row[col.label]"
                                [style.background]="sourceColors[row[col.label]]"
                                [style.color]="'white'"
                        >{{ row[col.label] }}</span
                        >
                        </mat-cell>
                    }
                    @if (col.header === 'APPOINTMENT_STATUS') {
                        <mat-cell *matCellDef="let row">
                            <mat-icon
                                    [fontIcon]="getOrderStatusIcon(row[col.label])"
                                    [matTooltip]="row[col.label] | translate"
                                    [style.color]="getOrderStatusColor(row[col.label])"
                                    fontSet="mdi"
                            ></mat-icon>
                        </mat-cell>
                    }
                    @if (col.header === 'PRIORITY') {
                        <mat-cell *matCellDef="let row">
                            <mat-icon
                                    fontIcon="mdi-circle"
                                    fontSet="mdi"
                                    [matTooltip]="priorityMap[_get(row, col.value)]"
                                    [ngStyle]="{
              background:
                'linear-gradient(45deg, black, ' +
                getPriorityColor(priorityMap[_get(row, col.value)]) +
                ', white)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }"
                            ></mat-icon>
                        </mat-cell>
                    }
                    @if (!['DATE', 'APPOINTMENT_STATUS', 'PRIORITY', 'Source', 'TIME'
                    ].includes(col.header)) {
                        <mat-cell *matCellDef="let row"> {{ _get(row, col.value) }}</mat-cell>
                    }
                </ng-container>
            }

            <!--actions-->
            <ng-container matColumnDef="action" stickyEnd>
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <button
                            (click)="onScheduleAppointment(row)"
                            [disabled]="
              !(
                profile &&
                profile.scheduleRequestedOrder !== 'NONE' &&
                isEditable(row)
              ) || row.appointmentStatus === 'planned'
            "
                            [matTooltip]="'SCHEDULE_EXAM' | translate"
                            mat-icon-button
                    >
                        <mat-icon fontIcon="mdi-calendar-check" fontSet="mdi"></mat-icon>
                    </button>
                    <button
                            (click)="editAppointment(row)"
                            [disabled]="
              !(profile && profile.updateOrder !== 'NONE' && isEditable(row))
            "
                            [matTooltip]="'APPROVE' | translate"
                            mat-icon-button
                    >
                        <mat-icon fontIcon="mdi-calendar-edit" fontSet="mdi"></mat-icon>
                    </button>

                    <mat-menu #orderMenu="matMenu" [overlapTrigger]="true">
                        <button
                                (click)="onCancelAppointment(row)"
                                [disabled]="
                !(profile && profile.cancelOrder !== 'NONE' && isEditable(row))
              "
                                mat-menu-item
                        >
                            <mat-icon fontIcon="mdi-close-circle" fontSet="mdi"></mat-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                                (click)="onRestoreAppointment(row)"
                                [disabled]="row.appointmentStatus !== 'canceled'"
                                mat-menu-item
                        >
                            <mat-icon fontIcon="mdi-close-circle" fontSet="mdi"></mat-icon>
                            {{ "RESTORE" | translate }}
                        </button>
                        <mat-divider></mat-divider>
                        <button (click)="deleteApt(row)" mat-menu-item>
                            <mat-icon fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
                            {{ "DELETE" | translate }}
                        </button>
                    </mat-menu>

                    <button [matMenuTriggerFor]="orderMenu" mat-icon-button>
                        <mat-icon fontIcon="mdi-dots-horizontal" fontSet="mdi"></mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <!--header-->
            <mat-header-row
                    *matHeaderRowDef="columnsToDisplay; sticky: true"
            ></mat-header-row>
            <mat-row
                    [@rowsAnimation]=""
                    *matRowDef="let row; columns: columnsToDisplay"
            ></mat-row>
        </mat-table>

        <div
                [style.display]="resultsLength === 0 ? '' : 'none'"
                class="fx-table-empty"
        >
            {{ "NOFOUND" | translate }}
        </div>
    </div>

    <mat-paginator
        #paginator
        [length]="dataSource.data.length"
        [pageIndex]="0"
        [pageSizeOptions]="[10, 25, 50, 100]"
        [pageSize]="defaultPageSize"
        [showFirstLastButtons]="true"
    >
    </mat-paginator>
</div>
