<div class="fx-layout-column-nowrap fx-fill">


    <div *ngIf="searchService.newSearchView(); else searchTemplate"></div>
    <ng-template #searchTemplate>
        <div class="flex flex-col md:items-start md:justify-start sm:justify-start lg:flex-row lg:gap-1 lg:p-3 lg:flex-wrap lg:items-end lg:fx-search-area" [formGroup]="filterForm">

            @if (profile && profile.manageExam === 'ALL') {
                <mat-form-field subscriptSizing="dynamic">
                    <mat-label>{{ "RADIOLOGIST" | translate }}</mat-label>
                    <mat-select formControlName="physicianId">
                        <mat-option [value]="null">{{ "ALL" | translate }}</mat-option>
                        @for (option of physicians; track $index) {
                            <mat-option [value]="option.id">{{ option["fullName"] }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            }
            <div>
                <h5 style="margin: 6px 0" class="label-option">
                    {{ "MODALITY" | translate }}
                </h5>
                <mat-button-toggle-group
                        aria-label="modality"
                        formControlName="modality"
                        multiple
                        name="modality"
                >
                    @for (modality of modalities; track $index) {
                        <mat-button-toggle [value]="modality">
                            {{ modality }}
                        </mat-button-toggle>
                    }
                </mat-button-toggle-group>
            </div>

            <div>
                <h5 style="margin: 6px 0" class="label-option">
                    {{ "REPORT_STATUS" | translate }}
                </h5>
                <mat-button-toggle-group
                        aria-label="Report status"
                        formControlName="completedReportStatus"
                        multiple
                        name="completedReportStatus"
                >
                    @for (rule of columnsFormattingRules | filterBy: 'REPORTING_STATUS';
                        track $index) {
                        <mat-button-toggle
                                [matTooltip]="rule.primaryFormatValue | translate"
                                [value]="rule.primaryFormatValue"
                        >
                            @switch (rule.formattingStyle?.displayMode) {
                                @case ('ICON') {
                                    <mat-icon
                                            [fontIcon]="rule.formattingStyle?.icon"
                                            [ngStyle]="{
              background:
                'linear-gradient(45deg, black, ' +
                rule.formattingStyle?.color +
                ', white)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }"
                                            fontSet="mdi"
                                    ></mat-icon>
                                }
                                @default {
                                    <span [style.color]="rule.formattingStyle?.color">{{
                                            rule.primaryFormatValue | translate
                                        }}</span>
                                }
                            }
                        </mat-button-toggle>
                    }
                </mat-button-toggle-group>
            </div>
            <div>
                <h5 style="margin: 6px 0" class="label-option">
                    {{ "PATIENT_STATUS" | translate }}
                </h5>
                <mat-button-toggle-group
                        aria-label="patient status"
                        formControlName="patientStatus"
                        multiple
                        name="patientStatus"
                >
                    @for (rule of columnsFormattingRules | filterBy: 'PATIENT_STATUS';
                        track $index) {
                        <mat-button-toggle
                                [matTooltip]="rule.primaryFormatValue | translate"
                                [value]="rule.primaryFormatValue"
                        >
                            @switch (rule.formattingStyle?.displayMode) {
                                @case ('ICON') {
                                    <mat-icon
                                            [fontIcon]="rule.formattingStyle?.icon"
                                            [ngStyle]="{
              background:
                'linear-gradient(45deg, black, ' +
                rule.formattingStyle?.color +
                ', white)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }"
                                            fontSet="mdi"
                                    ></mat-icon>
                                }
                                @default {
                                    <span [style.color]="rule.formattingStyle?.color">{{
                                            rule.primaryFormatValue | translate
                                        }}</span>
                                }
                            }
                        </mat-button-toggle>
                    }
                </mat-button-toggle-group>
            </div>

            @if (generalSetting?.billingRequired && profile?.managePayment) {
                <div>
                    <h5 style="margin: 6px 0" class="label-option">
                        {{ "PAYMENT_STATUS" | translate }}
                    </h5>
                    <mat-button-toggle-group
                            aria-label="payment status"
                            formControlName="paymentStatus"
                            multiple
                            name="paymentStatus"
                    >
                        @for (rule of columnsFormattingRules | filterBy: 'PAYMENT_STATUS';
                            track $index) {
                            <mat-button-toggle
                                    [matTooltip]="rule.primaryFormatValue | translate"
                                    [value]="rule.primaryFormatValue"
                            >
                                @switch (rule.formattingStyle?.displayMode) {
                                    @case ('ICON') {
                                        <mat-icon
                                                [fontIcon]="rule.formattingStyle?.icon"
                                                [ngStyle]="{
              background:
                'linear-gradient(45deg, black, ' +
                rule.formattingStyle?.color +
                ', white)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }"
                                                fontSet="mdi"
                                        ></mat-icon>
                                    }
                                    @default {
                                        <span [style.color]="rule.formattingStyle?.color">{{
                                                rule.primaryFormatValue | translate
                                            }}</span>
                                    }
                                }
                            </mat-button-toggle>
                        }
                    </mat-button-toggle-group>
                </div>
            }


            <div>
                <h5 style="margin: 6px 0">{{ "PERIOD" | translate }}</h5>
                <mat-button-toggle-group
                    #group="matButtonToggleGroup"
                    (change)="changeRange($event)"
                    aria-label="Period"
                    formControlName="period"
                    name="period"
                >
                    <mat-button-toggle value="TODAY">
                        {{ "TODAY" | translate }}
                    </mat-button-toggle>
                    <mat-button-toggle value="YESTERDAY">
                        {{ "YESTERDAY" | translate }}
                    </mat-button-toggle>
                    <mat-button-toggle value="3D">
                        {{ "THREE_DAYS" | translate }}
                    </mat-button-toggle>
                    <mat-button-toggle value="1W">
                        {{ "ONE_WEEK" | translate }}
                    </mat-button-toggle>
                    <mat-button-toggle value="1M">
                        {{ "ONE_MONTH" | translate }}
                    </mat-button-toggle>
                    <mat-button-toggle value="3M">
                        {{ "THREE_MONTHS" | translate }}
                    </mat-button-toggle>
                    <mat-button-toggle value="OT">
                        {{ "ALL" | translate }}
                    </mat-button-toggle>

                </mat-button-toggle-group>
            </div>

            @if (group.value === 'OT') {
                <mat-form-field subscriptSizing="dynamic" class="mt-2">
                    <mat-date-range-input [rangePicker]="picker">
                        <input
                                [placeholder]="'FROM' | translate"
                                formControlName="startDate"
                                matStartDate
                                (dateInput)="changePeriod()"
                        />
                        <input
                                [placeholder]="'TO' | translate"
                                formControlName="endDate"
                                matEndDate
                                (dateInput)="changePeriod()"
                        />
                    </mat-date-range-input>
                    <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                    <mat-date-range-picker
                        #picker
                        (closed)="changePeriod()"
                    ></mat-date-range-picker>
                </mat-form-field>
            }
        </div>
    </ng-template>

    <mat-drawer-container [hasBackdrop]="false" class="fx-grow-1">
        <mat-drawer
            #drawer
            [style.marginTop.px]="-1"
            [style.width.px]="348"
            mode="over"
            position="end"
        >
            <ft-table-formatter
                    (closeEvent)="drawer.toggle()"
                    (saveEvent)="onSaveTableConfig($event)"
                    [tableConfig]="schedulerTableConfig"
            ></ft-table-formatter>
        </mat-drawer>
        <mat-drawer-content>
            <div
                    class="fx-layout-column-nowrap fx-fill-width"
                    style="height: calc(100% - 40px)"
            >
                <div style="overflow: auto; height: calc(100vh - 118px)">
                    <mat-menu #menu2="matMenu" [overlapTrigger]="true">
                        <div
                                (cdkDropListDropped)="drop2($event)"
                                cdkDropList
                                class="ft-drag-list"
                        >
                            @for (column of examsColumns; track $index) {
                                <button
                                        (click)="$event.stopPropagation(); toggleColumn2(column)"
                                        cdkDrag
                                        class="ft-drag-box"
                                        mat-menu-item
                                >
                                    <mat-icon
                                            fontIcon="{{
                    column.hidden
                      ? 'mdi-checkbox-blank-circle-outline'
                      : 'mdi-check-circle'
                  }}"
                                            fontSet="mdi"
                                            style="color: #1565c0"
                                    ></mat-icon>
                                    {{ column.header | translate }}
                                </button>
                            }
                        </div>
                    </mat-menu>
                    <mat-table
                            [dataSource]="dataSource"
                            [matSortActive]="'id'"
                            [matSortDirection]="'asc'"
                            [trackBy]="trackById"
                            class="expandable-rows-table fx-fill-height fx-w-fit"
                            matSort
                            [matSortDisableClear]="true"
                            [multiTemplateDataRows]="true"
                    >
                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                            <mat-header-cell *matHeaderCellDef>
                                <mat-checkbox
                                        (change)="$event ? masterToggle() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                                >
                                </mat-checkbox>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <mat-checkbox
                                        (change)="$event ? selection.toggle(row) : null"
                                        (click)="$event.stopPropagation()"
                                        [checked]="selection.isSelected(row)"
                                >
                                </mat-checkbox>
                            </mat-cell>
                            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                        </ng-container>

                        <!-- Expand Column -->
                        <ng-container matColumnDef="expand">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <button mat-icon-button (click)="expandRow(row)"
                                        [matTooltip]="(row === expandedElement ? 'COLLAPSE' : 'EXPAND') | translate">
                                    <mat-icon
                                            [fontIcon]="row === expandedElement ? 'mdi-chevron-down' : 'mdi-chevron-right'"
                                            fontSet="mdi"
                                    ></mat-icon>
                                </button>

                            </mat-cell>
                            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                        </ng-container>

                        <!-- Nested row-->
                        <ng-container matColumnDef="expandedRow">
                            <mat-cell
                                    *matCellDef="let row"
                                    [attr.colspan]="columnsToDisplay.length"
                            >
                                <div
                                        [@detailExpand]="
                    row === expandedElement ? 'expanded' : 'collapsed'
                  "
                                        class="example-element-detail"
                                >
                                    @if (expandedElement) {
                                        <table class="nested-table">
                                            <thead>
                                            <tr>
                                                @for (col of examsColumnsToDisplay; track $index) {
                                                    <th>
                                                        {{ col.header | translate }}
                                                    </th>
                                                }
                                                <th>
                                                    <button
                                                            [matMenuTriggerFor]="menu2"
                                                            [style.float]="'right'"
                                                            mat-icon-button
                                                    >
                                                        <mat-icon
                                                                class="menu-btn"
                                                                fontIcon="mdi-dots-vertical"
                                                                fontSet="mdi"
                                                        ></mat-icon>
                                                    </button>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                @for (el of expandedElement.workflowItems; track $index) {
                                                    <tr>
                                                        @for (col of examsColumnsToDisplay; track $index) {
                                                            @if (col.header === 'SPSSTATUS') {
                                                                <td>
                                                                    <mat-icon
                                                                            [ngClass]="{
                              'mdi-spin':
                                getPatientStatusIcon(el) === 'mdi-timelapse'
                            }"
                                                                            [ngStyle]="{
                              color: getIconColor(el),
                              fontSize: '20px !important'
                            }"
                                                                            fontIcon="{{ getPatientStatusIcon(el) }}"
                                                                            fontSet="mdi"
                                                                    ></mat-icon>
                                                                </td>
                                                            }
                                                            @if (col.header === 'PROCEDURE_TYPE') {
                                                                <td>
                          <span
                                  [style.background-color]="getExamColor(el)"
                                  class="ft-label"
                          >{{ el[col.attr] }}</span
                          >
                                                                </td>
                                                            }
                                                            @if (col.header === 'REPORTING_STATUS') {
                                                                <td>
                                                                    <mat-icon
                                                                            [ngStyle]="{
                              color: getReportIconColor(el[col.attr]),
                              fontSize: '20px !important'
                            }"
                                                                            fontIcon="{{
                              getReportingStatusIcon(el[col.attr])
                            }}"
                                                                            fontSet="mdi"
                                                                    >
                                                                    </mat-icon>
                                                                </td>
                                                            }
                                                            @if (['PERFORMING_PHYSICIAN', 'TECHNICIAN'
                                                            ].includes(col.header)) {
                                                                <td>
                                                                    {{ shortenName(el[col.attr]) }}
                                                                </td>
                                                            }
                                                            @if (['EXAM_START', 'EXAM_FINISH'].includes(
                                                                col.header)) {
                                                                <td>
                                                                    {{ el[col.attr] | date : "HH:mm" }}
                                                                </td>
                                                            }
                                                            @if (col.header === 'PATIENTARRIVAL') {
                                                                <td>
                                                                    {{ el[col.attr] | date : todayFormat }}
                                                                </td>
                                                            }
                                                            @if (col.header === 'PAYMENT_STATUS') {
                                                                <td>
                          <span
                                  [class.animate]="el[col.attr] === 'NOT_PAID'"
                                  [ngStyle]="{
                              background: getPaymentStatusColor(el[col.attr]),
                              borderRadius: '50%'
                            }"
                          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
                          >
                                                                </td>
                                                            }
                                                            @if (['TOTAL_AMOUNT', 'PAID_AMOUNT', 'DISCOUNT',
                                                                'LEFT_AMOUNT'].includes(col.header)) {
                                                                <td>
                                                                    {{
                                                                        el[col.attr]
                                                                            | numeric
                                                                            : {
                                                                                numberFormat,
                                                                                comma: true
                                                                            }
                                                                    }}
                                                                </td>
                                                            }
                                                            @if (!specialFormat(col.header)) {
                                                                <td>
                                                                    {{ el[col.attr] }}
                                                                </td>
                                                            }
                                                        }

                                                        <td class="fx-layout-row-nowrap">
                                                            <button
                                                                    (click)="deliverReport(el)"
                                                                    [disabled]="
                              !isGranted(el, 'printReport') ||
                              !canPrintReport(el.reportStatus)
                            "
                                                                    [matTooltip]="'REPORT_DELIVERED' | translate"
                                                                    color="warn"
                                                                    mat-icon-button
                                                            >
                                                                <mat-icon
                                                                        [class.btn-action-deliver]="
                                isGranted(el, 'printReport') &&
                                canPrintReport(el.reportStatus)
                              "
                                                                        [style.color]="'inherit'"
                                                                        fontIcon="mdi-file-move"
                                                                        fontSet="mdi"
                                                                ></mat-icon>
                                                            </button>

                                                            <button
                                                                    [disabled]="el.old"
                                                                    [matMenuTriggerFor]="scMenu"
                                                                    mat-icon-button
                                                            >
                                                                <mat-icon
                                                                        fontIcon="mdi-dots-horizontal"
                                                                        fontSet="mdi"
                                                                ></mat-icon>
                                                            </button>

                                                            <mat-menu #scMenu="matMenu" [overlapTrigger]="true">
                                                                @if (profile && profile.editExam !== 'NONE' &&
                                                                el.examinationStarted === null) {
                                                                    <button (click)="editExam(el)" mat-menu-item>
                                                                        <mat-icon
                                                                                fontIcon="mdi-pencil"
                                                                                fontSet="mdi"
                                                                        ></mat-icon>
                                                                        {{ "EDIT" | translate }}
                                                                    </button>
                                                                }
                                                                <button
                                                                        (click)="sendReport(row)"
                                                                        [disabled]="!canPrintReport(el.reportStatus)"
                                                                        class="fire-action"
                                                                        mat-menu-item
                                                                >
                                                                    <mat-icon
                                                                            fontIcon="mdi-send"
                                                                            fontSet="mdi"
                                                                    ></mat-icon>
                                                                    {{ "SEND_REPORT" | translate }}
                                                                </button>
                                                                <button
                                                                        (click)="sendSMS(el)"
                                                                        class="fire-action"
                                                                        mat-menu-item
                                                                >
                                                                    <mat-icon
                                                                            fontIcon="mdi-email-plus"
                                                                            fontSet="mdi"
                                                                    ></mat-icon>
                                                                    {{ "SEND_SMS" | translate }}
                                                                </button>
                                                                <mat-divider></mat-divider>
                                                                <button (click)="debitStock(el)" mat-menu-item>
                                                                    <mat-icon
                                                                            fontIcon="mdi-store"
                                                                            fontSet="mdi"
                                                                    ></mat-icon>
                                                                    {{ "PHARMACY" | translate }}
                                                                </button>
                                                                <mat-divider></mat-divider>
                                                                <button
                                                                        (click)="enterPatient(el)"
                                                                        [disabled]="
                                cannot('authorizeExam') ||
                                !isGranted(el, 'authorizeExam')
                              "
                                                                        mat-menu-item
                                                                >
                                                                    <mat-icon
                                                                            fontIcon="mdi-login"
                                                                            fontSet="mdi"
                                                                    ></mat-icon>
                                                                    {{ "ENTER_PATIENT" | translate }}
                                                                </button>

                                                                <button
                                                                        (click)="onPatientLeave(el)"
                                                                        [disabled]="
                                cannot('exitPatient') ||
                                !isGranted(el, 'exitPatient')
                              "
                                                                        mat-menu-item
                                                                >
                                                                    <mat-icon
                                                                            fontIcon="mdi-logout"
                                                                            fontSet="mdi"
                                                                    ></mat-icon>
                                                                    {{ "PATIENTLEAVE" | translate }}
                                                                </button>

                                                                <button (click)="addComment(el)" mat-menu-item>
                                                                    <mat-icon
                                                                            fontIcon="mdi-comment-alert"
                                                                            fontSet="mdi"
                                                                    ></mat-icon>
                                                                    {{ "ADD_COMMENT" | translate }}
                                                                </button>

                                                                <mat-divider></mat-divider>
                                                                <button
                                                                        (click)="downloadBooklet(el)"
                                                                        [disabled]="
                                cannot('printReport') ||
                                !isGranted(el, 'printBooklet')
                              "
                                                                        mat-menu-item
                                                                >
                                                                    <mat-icon
                                                                            fontIcon="mdi-cloud-download"
                                                                            fontSet="mdi"
                                                                    ></mat-icon>
                                                                    {{ "DOWNLOAD_BOOKLET" | translate }}
                                                                </button>

                                                                @if (generalSetting?.reportPrintMode === 'CHROME') {
                                                                    <button
                                                                            (click)="printReportingTask(el)"
                                                                            [disabled]="
                                cannot('printReport') ||
                                !isGranted(el, 'printReport')
                              "
                                                                            mat-menu-item
                                                                    >
                                                                        <mat-icon
                                                                                fontIcon="mdi-printer"
                                                                                fontSet="mdi"
                                                                        ></mat-icon>
                                                                        {{ "PRINT_REPORT" | translate }}
                                                                    </button>

                                                                    <button
                                                                            (click)="printBooklet(el)"
                                                                            [disabled]="
                                cannot('printReport') ||
                                !isGranted(el, 'printBooklet')
                              "
                                                                            mat-menu-item
                                                                    >
                                                                        <mat-icon
                                                                                fontIcon="mdi-printer"
                                                                                fontSet="mdi"
                                                                        ></mat-icon>
                                                                        {{ "PRINT_BOOKLET" | translate }}
                                                                    </button>
                                                                }
                                                                <ng-template>
                                                                    <button
                                                                            [disabled]="
                                  cannot('printReport') ||
                                  !isGranted(el, 'printReport')
                                "
                                                                            [matMenuTriggerFor]="printersMenu3"
                                                                            mat-menu-item
                                                                    >
                                                                        <mat-icon
                                                                                fontIcon="mdi-printer"
                                                                                fontSet="mdi"
                                                                        ></mat-icon>
                                                                        {{ "PRINT_REPORT" | translate }}
                                                                    </button>

                                                                    <button
                                                                            [disabled]="
                                  cannot('printReport') ||
                                  !isGranted(el, 'printBooklet')
                                "
                                                                            [matMenuTriggerFor]="printersMenu4"
                                                                            mat-menu-item
                                                                    >
                                                                        <mat-icon
                                                                                fontIcon="mdi-printer"
                                                                                fontSet="mdi"
                                                                        ></mat-icon>
                                                                        {{ "PRINT_BOOKLET" | translate }}
                                                                    </button>

                                                                    <mat-menu #printersMenu3="matMenu">
                                                                        @for (printer of printers; track $index) {
                                                                            <button
                                                                                    (click)="
                                    printReportingTask(el, printer['label'])
                                  "
                                                                                    mat-menu-item
                                                                            >
                                                                                <mat-icon
                                                                                        fontIcon="mdi-printer"
                                                                                        fontSet="mdi"
                                                                                ></mat-icon>
                                                                                {{ printer["label"] }}
                                                                            </button>
                                                                        }
                                                                    </mat-menu>
                                                                    <mat-menu #printersMenu4="matMenu">
                                                                        @for (printer of printers; track $index) {
                                                                            <button
                                                                                    (click)="printBooklet(el, printer['label'])"
                                                                                    mat-menu-item
                                                                            >
                                                                                <mat-icon
                                                                                        fontIcon="mdi-printer"
                                                                                        fontSet="mdi"
                                                                                ></mat-icon>
                                                                                {{ printer["label"] }}
                                                                            </button>
                                                                        }
                                                                    </mat-menu>
                                                                </ng-template>

                                                                <button
                                                                        (click)="showPatientFolder(el)"
                                                                        [disabled]="!can(el, 'viewPatientFolder')"
                                                                        [matTooltip]="'PATIENT_FOLDER' | translate"
                                                                        mat-menu-item
                                                                >
                                                                    <mat-icon
                                                                            fontIcon="mdi-folder-account"
                                                                            fontSet="mdi"
                                                                    ></mat-icon>
                                                                    {{ "PATIENT_FOLDER" | translate }}
                                                                </button>

                                                                <mat-divider></mat-divider>
                                                                <button (click)="sendHL7Report(el)" mat-menu-item>
                                                                    <mat-icon
                                                                            fontIcon="mdi-file-upload"
                                                                            fontSet="mdi"
                                                                    ></mat-icon>
                                                                    {{ "BROADCAST_HL7_REPORT" | translate }}
                                                                </button>
                                                                <mat-divider></mat-divider>

                                                                <button
                                                                        (click)="printRdvForResult(el)"
                                                                        [disabled]="
                                !can(el, 'createRdvForResult') ||
                                !isGranted(el, 'createRdvForResult')
                              "
                                                                        mat-menu-item
                                                                >
                                                                    <mat-icon
                                                                            fontIcon="mdi-printer"
                                                                            fontSet="mdi"
                                                                    ></mat-icon>
                                                                    {{ "RDV_FOR_RESULT" | translate }}
                                                                </button>

                                                                @if (profile && profile.manageExam !== 'NONE' &&
                                                                notPaid(el)) {
                                                                    <button (click)="deleteExam(el)" mat-menu-item>
                                                                        <mat-icon
                                                                                fontIcon="mdi-delete"
                                                                                fontSet="mdi"
                                                                        ></mat-icon>
                                                                        {{ "DELETE" | translate }}
                                                                    </button>
                                                                }
                                                            </mat-menu>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </mat-cell>
                        </ng-container>

                        <!--Table columns-->
                        @for (col of displayedColumns; track $index) {
                            <ng-container
                                    [class.hidden]="col.hidden"
                                    matColumnDef="{{ col.label }}"
                            >
                                <mat-header-cell
                                        *matHeaderCellDef
                                        [disabled]="!col.sortable"
                                        [matTooltip]="col.header | translate"
                                        mat-sort-header="{{ col.sortField }}"
                                >
                                    {{ col.header | translate }}
                                </mat-header-cell>

                                @switch (col.type) {
                                    @case ('DATE') {
                                        <mat-cell
                                                *matCellDef="let row"
                                                [ngStyle]="getColumnStyle(col.type, col, row)"
                                        >
                                            <span>{{ row[col.label] | date : todayFormat }}</span>
                                        </mat-cell>
                                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                    }
                                    @case ('BOOLEAN') {
                                        <mat-cell
                                                *matCellDef="let row"
                                                [ngStyle]="getColumnStyleDisplayMode(col.type, col, row)"
                                        >
                                            @switch (getColumnDisplayMode(col.header)) {
                                                @case ('ICON') {
                                                    <mat-icon
                                                            [fontIcon]="
                    getColumnFormattingIcon(col.header, row[col.label])
                  "
                                                            [matTooltip]="
                    (col.label === 'billed' && row[col.label]
                      ? 'N° ' + row['billNumber']
                      : row[col.label]
                      ? 'YES'
                      : ''
                    ) | translate
                  "
                                                            matTooltipPosition="left"
                                                            [ngStyle]="
                    getColumnFormattingIconStyle(col.header, row[col.label])
                  "
                                                            fontSet="mdi"
                                                    ></mat-icon>
                                                }
                                                @case ('BADGE') {
                                                    <span
                                                            [ngStyle]="
                    getColumnStyleDisplayMode(col.type, col, row, 'BADGE')
                  "
                                                            class="ft-badge"
                                                    >{{ (row[col.label] ? "YES" : "NO") | translate }}</span
                                                    >
                                                }
                                                @default {
                                                    {{ (row[col.label] ? "YES" : "NO") | translate }}
                                                }
                                            }
                                        </mat-cell>
                                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                    }
                                    @case ('STATUS') {
                                        <mat-cell
                                                *matCellDef="let row"
                                                [ngStyle]="getColumnStyleDisplayMode(col.type, col, row)"
                                        >
                                            @switch (getColumnDisplayMode(col.header)) {
                                                @case ('ICON') {
                                                    <mat-icon
                                                            [fontIcon]="
                    getColumnFormattingIcon(col.header, row[col.label])
                  "
                                                            [matTooltip]="row[col.label] | translate"
                                                            matTooltipPosition="left"
                                                            [ngStyle]="
                    getColumnFormattingIconStyle(col.header, row[col.label])
                  "
                                                            fontSet="mdi"
                                                    ></mat-icon>
                                                }
                                                @case ('BADGE') {
                                                    <span
                                                            [ngStyle]="
                    getColumnBooleanBadgeStyle(col.header, row[col.label])
                  "
                                                            class="ft-badge"
                                                    >{{ row[col.label] | translate }}</span
                                                    >
                                                }
                                                @default {
                                                    {{ row[col.label] | translate }}
                                                }
                                            }
                                        </mat-cell>
                                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                    }
                                    @case ('NUMERIC') {
                                        <mat-cell
                                                *matCellDef="let row"
                                                [ngStyle]="getColumnStyle(col.type, col, row)"
                                        >
                                            {{
                                                row
                                                    | sumWorkItemBy : col.label
                                                    | numeric
                                                    : {
                                                        numberFormat,
                                                        comma: col.label != "count"
                                                    }
                                            }}
                                        </mat-cell>
                                        <mat-footer-cell *matFooterCellDef>
                <span style="width: 100%">{{
                        calculateSum(col.label, col.label !== "count")
                    }}</span>
                                        </mat-footer-cell>
                                    }
                                    @default {
                                        @if (col.unit === 'ICON') {
                                            <mat-cell *matCellDef="let row">
                                                @if (row[col.label]) {
                                                    <mat-icon
                                                            [fontIcon]="'mdi-message'"
                                                            [matTooltipClass]="'alert-tooltip'"
                                                            [matTooltip]="row[col.label]"
                                                            [ngStyle]="{
                    background: 'linear-gradient(45deg, black, #F44336, white)',
                    '-webkit-background-clip': 'text',
                    '-webkit-text-fill-color': 'transparent'
                  }"
                                                            fontSet="mdi"
                                                    ></mat-icon>
                                                }
                                            </mat-cell>
                                        }
                                        @if (col.unit !== 'ICON') {
                                            <mat-cell
                                                    *matCellDef="let row"
                                                    [ngStyle]="getColumnStyle(col.type, col, row)"
                                                    class="fx-gap-4"
                                            >
                                                @if (col.label === 'patientName') {
                                                    <div
                                                            class="fx-layout-row-nowrap fx-content-space-between fx-items-center"
                                                    >
                                                        <a
                                                                [routerLink]="'/patients/folder/' + row.patientId"
                                                                class="ft-patient-link"
                                                        >{{ row[col.label] }}</a
                                                        >

                                                        @if (row.smsSent) {
                                                            <mat-icon
                                                                    [matTooltip]="row.smsContent"
                                                                    [style.color]="'#FF5722'"
                                                                    fontIcon="mdi-email-check"
                                                                    fontSet="mdi"
                                                            ></mat-icon>
                                                        }
                                                    </div>
                                                }
                                                @if (col.label !== 'patientName') {
                                                    <span
                                                            class="fx-ellipsis"
                                                            [matTooltip]="
                    [
                      'insuranceName',
                      'procedureCode',
                      'referringPhysician',
                      'sideEffects'
                    ].includes(col.label)
                      ? (row[col.label] | translate)
                      : null
                  "
                                                    >{{ row[col.label] | translate }}</span
                                                    >
                                                }
                                            </mat-cell>
                                        }

                                        <mat-footer-cell *matFooterCellDef
                                        ><span style="width: 100%">{{
                                                calculateTotal(col.label)
                                            }}</span>
                                        </mat-footer-cell>
                                    }
                                }
                            </ng-container>
                        }

                        <!--actions-->
                        <ng-container matColumnDef="action" stickyEnd>
                            <mat-header-cell *matHeaderCellDef>
                                <div
                                        class="fx-layout-row fx-content-end fx-items-center fx-fill-width"
                                        style="padding-right: 8px"
                                >
                                    <span class="fx-grow-1"></span>
                                    <button [matMenuTriggerFor]="menu" mat-icon-button>
                                        <mat-icon
                                                class="table-custom-column-icon"
                                                fontIcon="mdi-table-cog"
                                                fontSet="mdi"
                                        ></mat-icon>
                                    </button>
                                    <button
                                            (click)="drawer.toggle()"
                                            [matTooltip]="'CONDITIONAL_FORMATTING' | translate"
                                            mat-icon-button
                                    >
                                        <mat-icon
                                                class="toolbar-icon-gradient"
                                                fontIcon="mdi-cog"
                                                fontSet="mdi"
                                        ></mat-icon>
                                    </button>
                                </div>
                            </mat-header-cell>
                            <mat-cell
                                    (click)="$event.stopPropagation()"
                                    *matCellDef="let row"
                            >
                                <div
                                        class="fx-layout-row-nowrap fx-content-end fx-items-center fx-fill-width"
                                        style="padding-right: 8px"
                                >
                                    <mat-menu #wfMenu="matMenu" [overlapTrigger]="true">
                                        <button (click)="newExam(row)" mat-menu-item>
                                            <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                                            {{ "NEW_EXAM" | translate }}
                                        </button>
                                        <mat-divider></mat-divider>
                                        @if (eFactActivated()) {
                                            <button mat-menu-item (click)="generateEfactUrl(row)">
                                                <mat-icon fontIcon="mdi-link" fontSet="mdi"></mat-icon>
                                                {{ "eFact" | translate }}
                                            </button>
                                        }
                                        @if (eHealthBoxActivated()) {
                                            <button mat-menu-item (click)="generateEHealthBoxUrl(row)">
                                                <mat-icon fontIcon="mdi-link" fontSet="mdi"></mat-icon>
                                                {{ "eHealthBox" | translate }}
                                            </button>
                                        }
                                        @if (profile && profile.editExam !== 'NONE' &&
                                        row.examinationStarted === null && row.count === 1) {
                                            <button (click)="editExam(row)" mat-menu-item>
                                                <mat-icon fontIcon="mdi-pencil" fontSet="mdi"></mat-icon>
                                                {{ "EDIT" | translate }}
                                            </button>
                                        }
                                        @if (profile?.printAttestation !== 'NONE') {
                                            <button (click)="printAttestation(row)" mat-menu-item>
                                                <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                                {{ "PRINT_ATTESTATION" | translate }}
                                            </button>
                                        }
                                        @if (row.count === 1) {
                                            <button
                                                    (click)="sendReport(row)"
                                                    [disabled]="!canPrintReport(row.reportStatus)"
                                                    class="fire-action"
                                                    mat-menu-item
                                            >
                                                <mat-icon fontIcon="mdi-send" fontSet="mdi"></mat-icon>
                                                {{ "SEND_REPORT" | translate }}
                                            </button>
                                        }
                                        <button (click)="addComment(row)" mat-menu-item>
                                            <mat-icon
                                                    fontIcon="mdi-comment-alert"
                                                    fontSet="mdi"
                                            ></mat-icon>
                                            {{ "ADD_COMMENT" | translate }}
                                        </button>
                                        <button
                                                (click)="sendSMS(row)"
                                                class="fire-action"
                                                [disabled]="cannot('sendSms')"
                                                mat-menu-item
                                        >
                                            <mat-icon
                                                    fontIcon="mdi-email-plus"
                                                    fontSet="mdi"
                                            ></mat-icon>
                                            {{ "SEND_SMS" | translate }}
                                        </button>
                                        @if (profile?.printThermalTicket !== 'NONE') {
                                            <button (click)="printTicket(row)" mat-menu-item>
                                                <mat-icon fontIcon="mdi-barcode" fontSet="mdi"></mat-icon>
                                                {{ "PRINT_STICKER" | translate }}
                                            </button>
                                        }
                                        @if (profile?.printPaymentReceipt !== 'NONE') {
                                            <button (click)="printPaymentReceipt(row)" mat-menu-item>
                                                <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                                {{ "PAYMENT_RECEIPT" | translate }}
                                            </button>
                                        }
                                        @if (row.count === 1) {
                                            <mat-divider></mat-divider>
                                            <button (click)="debitStock(row)" mat-menu-item>
                                                <mat-icon fontIcon="mdi-store" fontSet="mdi"></mat-icon>
                                                {{ "PHARMACY" | translate }}
                                            </button>
                                        }

                                        <mat-divider></mat-divider>
                                        <button
                                                (click)="enterPatient(row)"
                                                [disabled]="
                        cannot('authorizeExam') ||
                        !isGranted(row, 'authorizeExam')
                      "
                                                mat-menu-item
                                        >
                                            <mat-icon fontIcon="mdi-login" fontSet="mdi"></mat-icon>
                                            {{ "ENTER_PATIENT" | translate }}
                                        </button>
                                        @if (row.count === 1) {
                                            <button
                                                    (click)="onPatientLeave(row)"
                                                    [disabled]="
                        cannot('exitPatient') || !isGranted(row, 'exitPatient')
                      "
                                                    mat-menu-item
                                            >
                                                <mat-icon fontIcon="mdi-logout" fontSet="mdi"></mat-icon>
                                                {{ "PATIENTLEAVE" | translate }}
                                            </button>
                                        }

                                        <mat-divider></mat-divider>
                                        @if (row.count === 1) {
                                            <button
                                                    (click)="downloadBooklet(row)"
                                                    [disabled]="
                        cannot('printReport') || !isGranted(row, 'printBooklet')
                      "
                                                    mat-menu-item
                                            >
                                                <mat-icon
                                                        fontIcon="mdi-cloud-download"
                                                        fontSet="mdi"
                                                ></mat-icon>
                                                {{ "DOWNLOAD_BOOKLET" | translate }}
                                            </button>
                                        }
                                        @if (generalSetting?.reportPrintMode === 'CHROME') {
                                            @if (row.count === 1 && profile?.printReport !== 'NONE') {
                                                <button
                                                        (click)="printReportingTask(row)"
                                                        [disabled]="
                        cannot('printReport') || !isGranted(row, 'printReport')
                      "
                                                        mat-menu-item
                                                >
                                                    <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                                    {{ "PRINT_REPORT" | translate }}
                                                </button>
                                            }
                                            @if (row.count === 1 && profile?.printBooklet !== 'NONE') {
                                                <button
                                                        (click)="printBooklet(row)"
                                                        [disabled]="
                        cannot('printReport') || !isGranted(row, 'printBooklet')
                      "
                                                        mat-menu-item
                                                >
                                                    <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                                    {{ "PRINT_BOOKLET" | translate }}
                                                </button>
                                            }
                                        }
                                        <ng-template>
                                            @if (row.count === 1 && profile?.printReport !== 'NONE') {
                                                <button
                                                        [disabled]="
                          cannot('printReport') ||
                          !isGranted(row, 'printReport')
                        "
                                                        [matMenuTriggerFor]="printersMenu"
                                                        mat-menu-item
                                                >
                                                    <mat-icon
                                                            fontIcon="mdi-printer"
                                                            fontSet="mdi"
                                                    ></mat-icon>
                                                    {{ "PRINT_REPORT" | translate }}
                                                </button>
                                            }
                                            <mat-menu #printersMenu="matMenu">
                                                @for (printer of printers; track $index) {
                                                    <button
                                                            (click)="printReportingTask(row, printer['label'])"
                                                            mat-menu-item
                                                    >
                                                        <mat-icon
                                                                fontIcon="mdi-printer"
                                                                fontSet="mdi"
                                                        ></mat-icon>
                                                        {{ printer["label"] }}
                                                    </button>
                                                }
                                            </mat-menu>

                                            @if (row.count === 1 && profile?.printBooklet !== 'NONE') {
                                                <button
                                                        [disabled]="
                          cannot('printReport') ||
                          !isGranted(row, 'printBooklet')
                        "
                                                        [matMenuTriggerFor]="printersMenu2"
                                                        mat-menu-item
                                                >
                                                    <mat-icon
                                                            fontIcon="mdi-printer"
                                                            fontSet="mdi"
                                                    ></mat-icon>
                                                    {{ "PRINT_BOOKLET" | translate }}
                                                </button>
                                            }

                                            <mat-menu #printersMenu2="matMenu">
                                                @for (printer of printers; track $index) {
                                                    <button
                                                            (click)="printBooklet(row, printer['label'])"
                                                            mat-menu-item
                                                    >
                                                        <mat-icon
                                                                fontIcon="mdi-printer"
                                                                fontSet="mdi"
                                                        ></mat-icon>
                                                        {{ printer["label"] }}
                                                    </button>
                                                }
                                            </mat-menu>
                                        </ng-template>

                                        <button
                                                (click)="showPatientFolder(row)"
                                                [disabled]="!can(row, 'viewPatientFolder')"
                                                mat-menu-item
                                        >
                                            <mat-icon
                                                    fontIcon="mdi-folder-account"
                                                    fontSet="mdi"
                                            ></mat-icon>
                                            {{ "PATIENT_FOLDER" | translate }}
                                        </button>

                                        <mat-divider></mat-divider>
                                        <button (click)="sendHL7Report(row)" mat-menu-item>
                                            <mat-icon
                                                    fontIcon="mdi-file-upload"
                                                    fontSet="mdi"
                                            ></mat-icon>
                                            {{ "BROADCAST_HL7_REPORT" | translate }}
                                        </button>
                                        <mat-divider></mat-divider>

                                        <button
                                                (click)="printRdvForResult(row)"
                                                [disabled]="profile?.createRdvForResult === 'NONE'"
                                                mat-menu-item
                                        >
                                            <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                            {{ "RDV_FOR_RESULT" | translate }}
                                        </button>

                                        <button (click)="displayPrintingHistory(row)" mat-menu-item>
                                            <mat-icon
                                                    fontIcon="mdi-printer-eye"
                                                    fontSet="mdi"
                                            ></mat-icon>
                                            {{ "PRINTING_HISTORY" | translate }}
                                        </button>

                                        @if (profile && profile.manageExam !== 'NONE' && row.count
                                        === 1 && notPaid(row)) {
                                            <button (click)="deleteExam(row)" mat-menu-item>
                                                <mat-icon fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
                                                {{ "DELETE" | translate }}
                                            </button>
                                        }
                                    </mat-menu>
                                    @if (generalSetting?.billingRequired && !row['sent'] &&
                                    isAfterUpdate()) {
                                        <button
                                                (click)="sendToBilling(row)"
                                                [matTooltip]="'SYNC_BILLING' | translate"
                                                mat-icon-button
                                        >
                                            <mat-icon fontIcon="mdi-sync" fontSet="mdi"></mat-icon>
                                        </button>
                                    }
                                    @if (row.count === 1) {
                                        <button
                                                (click)="deliverReport(row)"
                                                [disabled]="
                      !isGranted(row, 'printReport') ||
                      !canPrintReport(row['reportStatus'])
                    "
                                                [matTooltip]="'REPORT_DELIVERED' | translate"
                                                color="warn"
                                                mat-icon-button
                                        >
                                            <mat-icon
                                                    [class.btn-action-deliver]="
                        isGranted(row, 'printReport') &&
                        canPrintReport(row['reportStatus'])
                      "
                                                    [style.color]="'inherit'"
                                                    fontIcon="mdi-file-move"
                                                    fontSet="mdi"
                                            ></mat-icon>
                                        </button>
                                    }
                                    @if (generalSetting?.billingRequired) {
                                        <button
                                                (click)="addPayment(row)"
                                                [disabled]="cannot('addPayment')"
                                                [matTooltip]="'PAYMENT' | translate"
                                                mat-icon-button
                                        >
                                            <mat-icon
                                                    fontIcon="mdi-currency-usd"
                                                    fontSet="mdi"
                                            ></mat-icon>
                                        </button>
                                    }

                                    <button
                                            [disabled]="row['old']"
                                            [matMenuTriggerFor]="wfMenu"
                                            mat-icon-button
                                    >
                                        <mat-icon
                                                fontIcon="mdi-dots-horizontal"
                                                fontSet="mdi"
                                        ></mat-icon>
                                    </button>
                                </div>
                            </mat-cell>
                            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                        </ng-container>

                        <mat-menu #menu="matMenu" [overlapTrigger]="true">
                            <div
                                    (cdkDropListDropped)="drop($event)"
                                    cdkDropList
                                    class="ft-drag-list"
                            >
                                @for (item of availableColumns; track $index) {
                                    <button
                                            (click)="$event.stopPropagation(); $event.stopImmediatePropagation();toggleColumn(item)"
                                            cdkDrag
                                            class="ft-drag-box"
                                            mat-menu-item
                                    >
                                        <mat-icon
                                                fontIcon="{{
                      item?.hidden
                        ? 'mdi-checkbox-blank-circle-outline'
                        : 'mdi-check-circle'
                    }}"
                                                fontSet="mdi"
                                        ></mat-icon>
                                        {{ item.header | translate }}
                                    </button>
                                }
                            </div>
                        </mat-menu>

                        <!--header-->
                        <mat-header-row
                                *matHeaderRowDef="$any(columnsToDisplay); sticky: true"
                        ></mat-header-row>

                        <mat-row
                                [@rowsAnimation]
                                *matRowDef="let row; columns: $any(columnsToDisplay)"
                                [ngClass]="{ 'fire-row': true, hide: row.hidden }"
                                [ngStyle]="getRowFormattingStyle(row)"
                        ></mat-row>

                        <mat-row
                                *matRowDef="let row; columns: ['expandedRow']"
                                [class.visible]="row === expandedElement"
                                class="detail-row"
                        ></mat-row>
                        <mat-footer-row
                                *matFooterRowDef="$any(columnsToDisplay); sticky: true"
                                [class.hidden-footer]="resultsLength === 0"
                                [style.min-height.px]="32"
                        ></mat-footer-row>
                    </mat-table>
                </div>
                <mat-paginator
                        [length]="resultsLength"
                        [pageIndex]="0"
                        [pageSizeOptions]="[20, 50, 100, 150, 200]"
                        [pageSize]="defaultPageSize"
                        [showFirstLastButtons]="true"
                >
                </mat-paginator>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
