import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {SchedulerService} from './scheduler.service';
import {MatDialog} from '@angular/material/dialog';
import moment from 'moment';
import {Router} from '@angular/router';
import {Profile, QueryParam, GeneralSettingDTO} from '../model';
import {TranslateService} from '@ngx-translate/core';
import {PacsSearchComponent, SharedService} from '../shared';
import {AppointmentEditComponent} from './appointment-edit/appointment-edit.component';
import {TasksListComponent} from '../shared/tasks-list/tasks-list.component';
import {ExamSchedulerComponent} from './exam-scheduler/exam-scheduler.component';
import {SearchService} from "../shared/advanced-search/search.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {filter} from "rxjs/operators";

@Component({
    selector: 'ft-scheduler',
    templateUrl: './scheduler.component.html',
    styleUrls: ['./scheduler.component.scss'],
})
export class SchedulerComponent implements OnInit {
    examStatuses: string[] = [];

    generalSetting: GeneralSettingDTO;

    profile: Profile;
    aets: any[];
    agendaActive: boolean = true;
    defaultView: string = '/schedule-manager';

    resourceFilterVisible: boolean = false;
    reasonForExams: any[];

    private schedulerView: string;

    currentRoute = 'SCHEDULER';

    public searchService = inject(SearchService);
    public _destroyRef = inject(DestroyRef);

    constructor(
        private service: SchedulerService,
        private sharedService: SharedService,
        private translate: TranslateService,
        private dialog: MatDialog,
        private router: Router
    ) {
        const user = JSON.parse(localStorage.getItem('user'));
        this.profile = user.profile;


        this.translate
            .get('EXAM_STATUSES')
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(res => (this.examStatuses = res.split('_')));

        this.service.broadcast.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(
            viewType =>
                (this.resourceFilterVisible =
                    viewType === 'resourceTimeGridDay')
        );

    }

    createExamWithPayment(queryParam: QueryParam = null) {
        if (queryParam)
            queryParam.patientID = queryParam.patientID
                ?.replaceAll('/', '_')
                ?.replaceAll(' ', '');
        this.dialog
            .open(ExamSchedulerComponent, {
                data: {
                    selectedDateRange: {
                        start: moment(),
                        end: moment().add(10, 'm'),
                    },
                    editable: true,
                    queryParam: queryParam,
                    panelClass: 'exam-dialog',
                },
                disableClose: true,
                position: {top: '16px'},
                minWidth: '50vw',
            })
            .afterClosed()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(res => {
                if (res && res['isrId']) {
                    //TODO(Should mark exam as finished)
                }
            });
    }

    addOrder() {
        this.dialog
            .open(AppointmentEditComponent, {
                data: {patient: null, isr: null},
                disableClose: true,
            })
            .afterClosed()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(/*res => this.createOrder(res)*/);
    }

    ngOnInit() {
        const datasets = 'aets,reasonForExams,generalSetting';

        this.sharedService.getDatasets(datasets)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(data => {
                datasets.split(',').forEach(it => (this[it] = data[it]));

                localStorage.setItem(
                    'eid_api_url',
                    this.generalSetting.scannerIdUrl
                );

                this.getDefaultView(this.generalSetting);
                if (
                    ['EXTERNAL_APPOINTMENTS', 'CALENDAR'].includes(
                        this.schedulerView
                    )
                )
                    this.router.navigateByUrl(this.defaultView).then(_ => null);
            });
    }


    private getDefaultView(setting: GeneralSettingDTO) {
        this.schedulerView = setting.schedulerDefaultView;
        this.currentRoute = setting.schedulerDefaultView;
        switch (setting.schedulerDefaultView) {
            case 'EXTERNAL_APPOINTMENTS':
                this.defaultView = '/scheduler/external-appointments';
                this.agendaActive = false;
                break;
            case 'CALENDAR':
                this.defaultView = '/scheduler/calendar';
                break;
            case 'SCHEDULER':
                this.defaultView = '/scheduler/schedule-manager';
                this.agendaActive = false;
                break;
        }
    }

    queryPacs() {
        this.dialog
            .open(PacsSearchComponent, {
                data: '',
                minHeight: '100vh',
                maxHeight: '100vh',
                minWidth: '90vw',
                disableClose: true,
            })
            .afterClosed()
            .pipe(
                filter(value => value?.key === 'new'),
                takeUntilDestroyed(this._destroyRef),
            ).subscribe(value => this.createExamWithPayment(value.data));
    }

    generateTasksList() {
        this.dialog.open(TasksListComponent);
    }
}
