<mat-card class="fx-fill-height fx-card">
    <mat-toolbar
            color="primary"
            class="fx-card-toolbar fx-layout-row-nowrap fx-content-end fx-items-center fx-gap-8">


        <span class="font-semibold text-2xl mr-4">{{ 'SCHEDULER' | translate }}</span>


        <button mat-flat-button [matMenuTriggerFor]="scheduleMenu">
            <mat-icon fontSet="mdi" fontIcon="mdi-menu-down"></mat-icon>
            {{ currentRoute | translate }}
        </button>

        <mat-menu #scheduleMenu="matMenu">
            <a mat-menu-item routerLink="schedule-manager" (click)="currentRoute = 'SCHEDULER'">
                <mat-icon
                        fontIcon="mdi-calendar-text"
                        fontSet="mdi"></mat-icon>
                &nbsp;<span class="fx-hide-lt-sm">{{
                    'SCHEDULER' | translate
                }}</span>
            </a>

            <a
                    (click)="agendaActive = true; currentRoute = 'CALENDAR'"
                    [queryParams]="{ res: 'aet' }"
                    mat-menu-item
                    routerLink="calendar">
                <mat-icon
                        class="toolbar-icon-gradient-1"
                        fontIcon="mdi-calendar-weekend"
                        fontSet="mdi"></mat-icon>
                &nbsp;<span class="fx-hide-lt-sm">{{
                    'CALENDAR' | translate
                }}</span>
            </a>

            @if (profile && profile.searchExam !== 'NONE') {
                <a
                        (click)="agendaActive = false; currentRoute = 'EXTERNAL_APPOINTMENTS'"
                        mat-menu-item
                        routerLink="external-appointments">
                    <mat-icon
                            class="toolbar-icon-gradient-2"
                            fontIcon="mdi-calendar-arrow-left"
                            fontSet="mdi"></mat-icon>
                    &nbsp;<span class="fx-hide-lt-sm">{{
                        'EXTERNAL_APPOINTMENTS' | translate
                    }}</span>
                </a>
            }

            <a
                    (click)="agendaActive = false; currentRoute = 'RDV_FOR_RESULT'"
                    mat-menu-item
                    routerLink="appointments-for-result">
                <mat-icon

                        fontIcon="mdi-clipboard-clock-outline"
                        fontSet="mdi"></mat-icon>
                &nbsp;<span class="fx-hide-lt-sm">{{
                    'RDV_FOR_RESULT' | translate
                }}</span>
            </a>
        </mat-menu>

        <span class="fx-grow-1"></span>

        <button (click)="addOrder()" color="warn" mat-raised-button>
            <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
            <span class="fx-hide-lt-sm">{{
                    'ADD_APPOINTMENT' | translate
                }}</span>
        </button>

        @if (profile && profile.scheduleExam !== 'NONE') {
            <button color="primary"
                    (click)="$event.stopPropagation(); createExamWithPayment()"
                    mat-raised-button>
                <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                <span class="fx-hide-lt-sm">{{ 'NEW_EXAM' | translate }}</span>
            </button>
        }




        <button mat-icon-button [matMenuTriggerFor]="extra">
            <mat-icon fontIcon="mdi-tune" fontSet="mdi"></mat-icon>
        </button>

        <mat-menu #extra="matMenu">

            <button [disabled]="profile?.printTaskList === 'NONE'" (click)="generateTasksList()" mat-menu-item>
                <mat-icon fontIcon="mdi-checkbox-marked-circle-auto-outline" fontSet="mdi"></mat-icon>
                <span class="fx-hide-lt-md">
					{{ 'GENERATE_TASK_LIST' | translate }}</span
                >
            </button>

            <button [disabled]="profile?.scheduleExam === 'NONE'"
                    (click)="$event.stopPropagation(); queryPacs()"
                    class="plus-no-outline"
                    mat-menu-item>
                <mat-icon

                        fontIcon="mdi-database-search-outline"
                        fontSet="mdi"></mat-icon>
                &nbsp;<span class="fx-hide-lt-sm">{{
                    'QUERY_PACS' | translate
                }}</span>
            </button>

            <mat-divider></mat-divider>

            <button (click)="searchService.toggleSearchView()" mat-menu-item>
                <mat-icon
                        fontSet="mdi"
                        fontIcon="mdi-pencil-ruler"
                ></mat-icon>
                {{ (searchService.newSearchView() ? "search.old": "search.new") | translate }}
            </button>

        </mat-menu>


    </mat-toolbar>
    <router-outlet></router-outlet>
</mat-card>
